<template>
  <v-container>

    <div class="v-data-table elevation-1 pa-2" v-if="group_stage">
      <div class="v-data-table__wrapper table-wrapper">
        <table>
          <thead>
            <tr>
              <th v-for="(header, idx) in headers" :key="idx" :class="(header.value == 'name') ? 'text-left pl-2 fixed-column' : 'text-center'">
                {{header.text}}
                <v-btn class="sort-btn" v-if="header.value != 'name'" text icon color="blue lighten-2" @click="sortItems(header.value, (sortDirection) ? false : true)">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
                
              </th>
            </tr>
          </thead>
          <tbody>
            <StandingsRow v-for="(property, index) in sortedItems" :key="index" :headers="headers" :tip="submitted[property]" :tipId="property" @myPoints="recivePoints" />
          </tbody>
        </table>
      </div>
    </div>

  </v-container>
</template>

<script>
  import StandingsRow from '../components/StandingsRow'
  export default {
    name: 'Standings',
     data() {
      return {
        sortDirection: false,
        sortByMatch: 'points',
        submitted: {},
        group_stage: false
      }
    },
    components: {
      StandingsRow
    },
    computed: {
      headers: function() {
        let header = [{
          text: "Namn",
          value: "name",
          width: 300
        }];

        for(const match of this.group_stage['matches']) {
          const obj = {
            text: this.$t(match.homeTeam.name) + " - " + this.$t(match.awayTeam.name),
            value: 'match_' + match.id,
            status: match.status,
            winner: (match.status == 'SCHEDULED') ? "" : match.score.winner
          }
          header.push(obj);
        }
        header.push({
          text: "Poäng",
          value: "points"
        })
        return header;
      },
      items: function() {
        return Object.keys(this.submitted);
      },
      sortedItems: function() {
        let items = Object.keys(this.submitted);

        if(this.sortByMatch == "points") {
          items.sort((a,b) => {
            if(this.sortDirection) {
              return this.submitted[a].points < this.submitted[b].points ? -1 : 1;
            }
            else {
              return this.submitted[b].points < this.submitted[a].points ? -1 : 1;
            }
          })
          return items;
        }
        else {
          const match = this.group_stage.matches.find(el => "match_" + el.id == this.sortByMatch);


          if(match) {
            let group = match.group;
            group = group.toLowerCase().replace(' ', '_');
          
            return items.sort((a,b) => {
              if(this.sortDirection) {

                const res = this.submitted[a].tip.groupForm[group]["match_" + match.id] < this.submitted[b].tip.groupForm[group]["match_" + match.id] ? -1 : 1;
                return res;
              }
              else {
                const res = this.submitted[b].tip.groupForm[group]["match_" + match.id] < this.submitted[a].tip.groupForm[group]["match_" + match.id] ? -1 : 1;
                return res;
              }

            })

          }
          else {
            return items;
          }
        }
        
      } 
    },
    methods: {
      sortItems(id, isDescending) {
        this.sortByMatch = id;
        this.sortDirection = isDescending;

      },
      recivePoints(value) {
        this.$set(this.submitted[value.id], 'points', value.points)
      }
    },
    created() {
      this.$http.get(process.env.VUE_APP_API + "/matches")
        .then((response) => {
          this.group_stage = response.data.data;
        })

      this.$http.get(process.env.VUE_APP_API + "/submitted")
        .then((response) => {
          this.submitted = response.data.data;
        })

    }
  }
</script>