<template>
  <v-chip :color="getColor">
    {{tip}}
  </v-chip>

</template>
<script>

export default {
    name: 'StandingsTip',
    components: {
    },
    props: {
      tip: {
        type: String
      },
      correct: {
        type: String
      },
      status: {
        type: String
      }
    },
    computed: {
      isCorrect: function() {
        if(this.tip == "1" && this.correct == "HOME_TEAM") {
          return true;
        }
        else if(this.tip == "X" && this.correct == "DRAW") {
          return true;
        }
        else if(this.tip == "2" && this.correct == "AWAY_TEAM") {
          return true;
        }
        return false;
      },
      getColor: function() {
        if(this.isCorrect && this.status == 'FINISHED') {
          return "green"
        }
        else if(this.isCorrect && this.status == 'IN_PLAY') {
          return "orange"
        }
        else if(this.isCorrect && this.status == 'PAUSED') {
          return "orange"
        }
        else if(!this.isCorrect && this.status == 'FINISHED') {
          return "red"
        }
        return "default"
      }
    },
    methods: {

    },
    watch: {

    },
    beforeMount: function() {
      this.$emit('isCorrect', this.isCorrect)
    }
}
</script>
