<template>
  <tr>
    <td v-for="(header, idx) in headers" :key="idx" :class="(header.value == 'name') ? 'text-left pl-2 fixed-column' : 'text-center'" style="font-size:14px;">
      <p style="margin-bottom: 0px;" v-if="header.value == 'name'">
        {{getTip(header.value)}}
      </p>
      <p v-else-if="header.value == 'points'">
        {{tip.points ? tip.points * 2 : 0}}
      </p>
      <StandingsTip v-else :tip="getTip(header.value)" :correct="header.winner" :status="header.status" @isCorrect="onIsCorrect" />
    </td>
  </tr>
</template>
<script>
import StandingsTip from '../components/StandingsTip'
export default {
    name: 'StandingsRow',
    components: {
      StandingsTip
    },
    data() {
      return {
        numOfCorrect: 0
      }
    },
    props: {
      headers: {
        type: Array
      },
      tip: {
        type: Object
      },
      tipId: {
        type: String
      }
    },
    computed: {
    
    },
    methods: {
      getTip(matchId) {
        if(matchId == 'name') {
          return this.tip.name
        }
        else {
          let tip = "NaN";
          for(const group in this.tip.tip.groupForm) {
          
            if(this.tip.tip.groupForm[group][matchId]) {
              tip = this.tip.tip.groupForm[group][matchId];
              break;
            }
      
          }

          return tip;
        }
      },
      onIsCorrect(value) {
        if(value) {
          this.numOfCorrect += 1;
          this.$emit('myPoints', {points:this.numOfCorrect, id: this.tipId}) 
        }
      }
    },
    watch: {

    },
    beforeMount: function() {

    }
}
</script>
